var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "step",
      class: { active: _vm.active },
      on: { mouseenter: _vm.mouseenter, mouseleave: _vm.mouseleave },
    },
    [
      _c(
        "div",
        { staticClass: "icon" },
        [_c("b-img", { attrs: { src: `images/${_vm.icon}.svg` } })],
        1
      ),
      _vm._v("\n  " + _vm._s(_vm.title) + "\n  "),
      _c("div", { staticClass: "details-popup", class: { right: _vm.right } }, [
        _c("h5", { class: { oops: _vm.blocking } }, [_vm._v(_vm._s(_vm.quip))]),
        _c("h4", [_vm._v(_vm._s(_vm.blurb))]),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.description) } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }