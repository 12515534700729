var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "steps" },
      [
        _vm._l(_vm.steps, function (step) {
          return [
            _c("vehicle-tracker-step", {
              key: step.title,
              attrs: {
                active: step.active,
                blocking: step.blocking,
                right: step.right,
                icon: step.icon,
                title: step.title,
                quip: step.quip,
                blurb: step.blurb,
                description: step.description,
              },
            }),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "road" }, [
      _c("div", { staticClass: "dash" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }